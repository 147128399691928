import { useEffect } from "react";

function Qualification() {

    useEffect(() => {
        const tabs = document.querySelectorAll('[data-target]'),
            tabContents = document.querySelectorAll('[data-content]')

        tabs.forEach(tab => {
            tab.addEventListener('click', () => {
                const target = document.querySelector(tab.dataset.target)

                tabContents.forEach(tabContent => {
                    tabContent.classList.remove('qualification__active')
                })

                target.classList.add('qualification__active')

                tabs.forEach(tab => {
                    tab.classList.remove('qualification__active')
                })
                tab.classList.add('qualification__active')
            })
        })
    })

    return (
        <section class="qualification section">
            <h2 class="section__title">Qualification</h2>
            <span class="section__subtitle">My personal journey</span>

            <div class="qualification__container container">
                <div class="qualification__tabs">
                    <div class="qualification__button button--flex" data-target='#education'>
                        <i class="uil uil-graduation-cap qualification__icon"></i>
                        Education
                    </div>

                    <div class="qualification__button button--flex qualification__active" data-target='#work'>
                        <i class="uil uil-briefcase-alt qualification__icon"></i>
                        Work
                    </div>
                </div>

                <div class="qualification__sections">

                    <div class="qualification__content" data-content id="education">

                        <div class="qualification__data">
                            <div>
                                <h3 class="qualification__title">Science Class</h3>
                                <span class="qualification__subtitle">Kertosono Senior Highschool</span>
                                <div class="qualification__calendar">
                                    <i class="uil uil-calendar-alt"></i>
                                    2011-2014
                                </div>
                            </div>

                            <div>
                                <span class="qualification__rounder"></span>
                                <span class="qualification__line"></span>
                            </div>
                        </div>

                        <div class="qualification__data">
                            <div></div>

                            <div>
                                <span class="qualification__rounder"></span>
                            </div>

                            <div>
                                <h3 class="qualification__title">Information System</h3>
                                <span class="qualification__subtitle">Airlangga University</span>
                                <div class="qualification__calendar">
                                    <i class="uil uil-calendar-alt"></i>
                                    2015-2019
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="qualification__content qualification__active" data-content id="work">

                        <div class="qualification__data">
                            <div>
                                <h3 class="qualification__title">Business Analyst - Intern</h3>
                                <span class="qualification__subtitle">Airlangga University</span>
                                <div class="qualification__calendar">
                                    <i class="uil uil-calendar-alt"></i>
                                    2017
                                </div>
                            </div>

                            <div>
                                <span class="qualification__rounder"></span>
                                <span class="qualification__line"></span>
                            </div>
                        </div>

                        <div class="qualification__data">
                            <div></div>

                            <div>
                                <span class="qualification__rounder"></span>
                                <span class="qualification__line"></span>
                            </div>

                            <div>
                                <h3 class="qualification__title">Software Engineer - BackEnd</h3>
                                <span class="qualification__subtitle">Twiscode Pte. Ltd</span>
                                <div class="qualification__calendar">
                                    <i class="uil uil-calendar-alt"></i>
                                    2019-2020
                                </div>
                            </div>
                        </div>

                        <div class="qualification__data">
                            <div>
                                <h3 class="qualification__title">Software Engineer - BackEnd</h3>
                                <span class="qualification__subtitle">Qiscus Pte. Ltd</span>
                                <div class="qualification__calendar">
                                    <i class="uil uil-calendar-alt"></i>
                                    2020-2021
                                </div>
                            </div>

                            <div>
                                <span class="qualification__rounder"></span>
                                <span class="qualification__line"></span>
                            </div>
                        </div>

                        <div class="qualification__data">
                            <div></div>

                            <div>
                                <span class="qualification__rounder"></span>
                            </div>
                            <div>
                                <h3 class="qualification__title">Software Engineer</h3>
                                <span class="qualification__subtitle">Tokopedia</span>
                                <div class="qualification__calendar">
                                    <i class="uil uil-calendar-alt"></i>
                                    2021-2022
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default Qualification;