import { useEffect } from "react";

function Form() {

    useEffect(() => {
        const scriptURL = 'https://script.google.com/macros/s/AKfycbwYICFaVAWsVd8Jngd3WevDwRikKn4Em8Q6LaEYsRxju5jiB4_z4rYtYHKnO3wQy9gV8w/exec'
        const form = document.forms['contact-form']

        const sendBtn = document.getElementById('send-button');
        const loadingBtn = document.getElementById('loading-button');
        const successAlert = document.getElementById('success-alert');
        const failAlert = document.getElementById('fail-alert');
        const closeSuccessBtn = document.getElementById('close-success');
        const closeFailBtn = document.getElementById('close-fail');

        form.addEventListener('submit', e => {
            e.preventDefault()
            sendBtn.classList.toggle('d-none')
            loadingBtn.classList.toggle('d-none')
            fetch(scriptURL, { method: 'POST', body: new FormData(form) })
                .then(response => {
                    console.log('Success!', response)
                    sendBtn.classList.toggle('d-none')
                    loadingBtn.classList.toggle('d-none')
                    successAlert.classList.toggle('d-none')
                    form.reset()
                })
                .catch(error => {
                    console.error('Error!', error.message)
                    sendBtn.classList.toggle('d-none')
                    loadingBtn.classList.toggle('d-none')
                    failAlert.classList.toggle('d-none')
                    form.reset()
                })
        })

        closeSuccessBtn.addEventListener('click', () => {
            successAlert.classList.toggle('d-none')
        })

        closeFailBtn.addEventListener('click', () => {
            failAlert.classList.toggle('d-none')
        })
    })

    return (
        <div>
            <div class="alert d-none" id="success-alert">
                <span class="closebtn" id="close-success">&times;</span>
                <strong>Thank you!</strong> Your message was sent successfully.
            </div>
            <div class="alert d-none" id="fail-alert">
                <span class="closebtn" id="close-fail">&times;</span>
                <strong>So sorry,</strong> Fail to send your message.
            </div>
            <form name="contact-form" action="" class="contact__form grid">
                <div class="contact__inputs grid">
                    <div class="contact__content">
                        <label for="" class="contact__label">Name</label>
                        <input type="text" class="contact__input" name="name" required />
                    </div>
                    <div class="contact__content">
                        <label for="" class="contact__label">Email</label>
                        <input type="email" class="contact__input" name="email" required />
                    </div>
                </div>
                <div class="contact__content">
                    <label for="" class="contact__label">Subject</label>
                    <input type="text" class="contact__input" name="subject" required />
                </div>
                <div class="contact__content">
                    <label for="" class="contact__label">Message</label>
                    <textarea name="message" id="" cols="0" rows="7" class="contact__input" required></textarea>
                </div>

                <div>
                    <button type="submit" class="button button--flex" id="send-button">
                        Send Message
                        <i class="uil uil-message button__icon"></i>
                    </button>
                    <button class="button button--flex d-none" id="loading-button" disabled>
                        Loading..
                        <i class="uil uil-spinner button__icon"></i>
                    </button>
                </div>
            </form>
        </div>
    );
}

export default Form;