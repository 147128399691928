import { useEffect } from "react";

function Services() {

    useEffect(() => {
        const modalViews = document.querySelectorAll('.services__modal'),
            modalBtns = document.querySelectorAll('.services__button'),
            modalCloses = document.querySelectorAll('.services__modal-close')

        let modal = function (modalClick) {
            modalViews[modalClick].classList.add('active-modal')
        }

        modalBtns.forEach((modalBtn, i) => {
            modalBtn.addEventListener('click', () => {
                modal(i)
            })
        })

        modalCloses.forEach((modalClose) => {
            modalClose.addEventListener('click', () => {
                modalViews.forEach((modalView) => {
                    modalView.classList.remove('active-modal')
                })
            })
        })
    })

    return (
        <section class="services section" id="services">
            <h2 class="section__title">Services</h2>
            <span class="section__subtitle">What I offer</span>

            <div class="services__container container grid">
                <div class="services__content">
                    <div>
                        <i class="uil uil-web-grid services__icon"></i>
                        <h3 class="services__title">Website <br /> Profile</h3>
                    </div>

                    <span class="button button--flex button--small button--link services__button">
                        View more
                        <i class="uil uil-arrow-right button__icon"></i>
                    </span>

                    <div class="services__modal">
                        <div class="services__modal-content">
                            <h4 class="services__modal-title">Website <br /> Profile</h4>
                            <i class="uil uil-times services__modal-close"></i>

                            <ul class="services__modal-services grid">
                                <li class="services__modal-service">
                                    <i class="uil uil-check-circle services__modal-icon"></i>
                                    <p>Develop profile website</p>
                                </li>
                                <li class="services__modal-service">
                                    <i class="uil uil-check-circle services__modal-icon"></i>
                                    <p>Deploy the website</p>
                                </li>
                                <li class="services__modal-service">
                                    <i class="uil uil-check-circle services__modal-icon"></i>
                                    <p>Reposition your company brand</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="services__content">
                    <div>
                        <i class="uil uil-arrow services__icon"></i>
                        <h3 class="services__title">Backend <br /> Developer</h3>
                    </div>

                    <span class="button button--flex button--small button--link services__button">
                        View more
                        <i class="uil uil-arrow-right button__icon"></i>
                    </span>

                    <div class="services__modal">
                        <div class="services__modal-content">
                            <h4 class="services__modal-title">Backend <br /> Developer</h4>
                            <i class="uil uil-times services__modal-close"></i>

                            <ul class="services__modal-services grid">
                                <li class="services__modal-service">
                                    <i class="uil uil-check-circle services__modal-icon"></i>
                                    <p>Design System</p>
                                </li>
                                <li class="services__modal-service">
                                    <i class="uil uil-check-circle services__modal-icon"></i>
                                    <p>Build APIs</p>
                                </li>
                                <li class="services__modal-service">
                                    <i class="uil uil-check-circle services__modal-icon"></i>
                                    <p>Deployment</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;