import Home from "./Components/Home";
import About from "./Components/About";
import Skills from "./Components/Skills";
import Qualification from "./Components/Qualification";
import Services from "./Components/Services";
import Portfolio from "./Components/Portfolio";
import Project from "./Components/Project";
// import Testimonial from "./Components/Testimonial";
import Contact from "./Components/Contact";

function Body() {
    return (
        <main class="main">
            <Home></Home>
            <About></About>
            <Skills></Skills>
            <Qualification></Qualification>
            <Services></Services>
            <Portfolio></Portfolio>
            <Project></Project>
            {/* <Testimonial></Testimonial> */}
            <Contact></Contact>
        </main>
    );
}

export default Body;