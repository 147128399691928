import { useEffect } from "react";

function Skills() {

    useEffect(() => {
        const skillsContent = document.getElementsByClassName('skills__content'),
            skillsHeader = document.querySelectorAll('.skills__header')

        function toggleSkills() {
            let itemClass = this.parentNode.className

            for (let i = 0; i < skillsContent.length; i++) {
                skillsContent[i].className = 'skills__content skills__close'
            }

            if (itemClass === 'skills__content skills__close') {
                this.parentNode.className = 'skills__content skills__open'
            }
        }

        skillsHeader.forEach((el) => {
            el.addEventListener('click', toggleSkills)
        })
    })

    return (
        <section class="skills section" id="skills">
            <h2 class="section__title">Skills</h2>
            <span class="section__subtitle">My technical skills</span>

            <div class="skills__container container grid">
                <div>
                    <div class="skills__content skills__open">
                        <div class="skills__header">
                            <i class="uil uil-brackets-curly skills__icon"></i>

                            <div>
                                <h1 class="skills_title">Programming Language</h1>
                                <span class="skills__subtitle">Programming language I used to use</span>
                            </div>

                            <i class="uil uil-angle-down skills__arrow"></i>
                        </div>

                        <div class="skills__list grid">
                            <div class="skills__data">
                                <div class="skills__titles">
                                    <h3 class="skills__name">Golang</h3>
                                </div>
                                <div class="skills__bar">
                                    <span class="skills__percentage skills__all"></span>
                                </div>
                            </div>

                            <div class="skills__data">
                                <div class="skills__titles">
                                    <h3 class="skills__name">PHP</h3>

                                </div>
                                <div class="skills__bar">
                                    <span class="skills__percentage skills__all"></span>
                                </div>
                            </div>

                            <div class="skills__data">
                                <div class="skills__titles">
                                    <h3 class="skills__name">Java</h3>

                                </div>
                                <div class="skills__bar">
                                    <span class="skills__percentage skills__all"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="skills__content skills__close">
                        <div class="skills__header">
                            <i class="uil uil-database skills__icon"></i>

                            <div>
                                <h1 class="skills_title">Database</h1>
                                <span class="skills__subtitle">DBMS I used to use</span>
                            </div>

                            <i class="uil uil-angle-down skills__arrow"></i>
                        </div>

                        <div class="skills__list grid">
                            <div class="skills__data">
                                <div class="skills__titles">
                                    <h3 class="skills__name">PostgreSQL</h3>

                                </div>
                                <div class="skills__bar">
                                    <span class="skills__percentage skills__all"></span>
                                </div>
                            </div>

                            <div class="skills__data">
                                <div class="skills__titles">
                                    <h3 class="skills__name">MySQL</h3>

                                </div>
                                <div class="skills__bar">
                                    <span class="skills__percentage skills__all"></span>
                                </div>
                            </div>

                            <div class="skills__data">
                                <div class="skills__titles">
                                    <h3 class="skills__name">Redis</h3>

                                </div>
                                <div class="skills__bar">
                                    <span class="skills__percentage skills__all"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="skills__content skills__close">
                        <div class="skills__header">
                            <i class="uil uil-server-network-alt skills__icon"></i>

                            <div>
                                <h1 class="skills_title">Message Broker</h1>
                                <span class="skills__subtitle">Message Broker I used to use</span>
                            </div>

                            <i class="uil uil-angle-down skills__arrow"></i>
                        </div>

                        <div class="skills__list grid">
                            <div class="skills__data">
                                <div class="skills__titles">
                                    <h3 class="skills__name">NSQ</h3>

                                </div>
                                <div class="skills__bar">
                                    <span class="skills__percentage skills__all"></span>
                                </div>
                            </div>

                            <div class="skills__data">
                                <div class="skills__titles">
                                    <h3 class="skills__name">MQTT</h3>

                                </div>
                                <div class="skills__bar">
                                    <span class="skills__percentage skills__all"></span>
                                </div>
                            </div>

                            <div class="skills__data">
                                <div class="skills__titles">
                                    <h3 class="skills__name">RabbitMQ</h3>

                                </div>
                                <div class="skills__bar">
                                    <span class="skills__percentage skills__all"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="skills__content skills__close">
                        <div class="skills__header">
                            <i class="uil uil-servers skills__icon"></i>

                            <div>
                                <h1 class="skills_title">Others</h1>
                                <span class="skills__subtitle">Other skills</span>
                            </div>

                            <i class="uil uil-angle-down skills__arrow"></i>
                        </div>

                        <div class="skills__list grid">
                            <div class="skills__data">
                                <div class="skills__titles">
                                    <h3 class="skills__name">gRPC</h3>

                                </div>
                                <div class="skills__bar">
                                    <span class="skills__percentage skills__all"></span>
                                </div>
                            </div>

                            <div class="skills__data">
                                <div class="skills__titles">
                                    <h3 class="skills__name">Microservices</h3>

                                </div>
                                <div class="skills__bar">
                                    <span class="skills__percentage skills__all"></span>
                                </div>
                            </div>

                            <div class="skills__data">
                                <div class="skills__titles">
                                    <h3 class="skills__name">Docker</h3>

                                </div>
                                <div class="skills__bar">
                                    <span class="skills__percentage skills__all"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Skills;