import { useEffect } from "react";
// import Swiper from 'swiper/swiper-bundle'
// import { Swiper } from "swiper/swiper-bundle";
// import 'swiper/swiper-bundle.min.css'

function Portfolio() {

    useEffect(() => {
        // let swiperPortfolio = new Swiper(".portfolio__container", {
        //     cssMode: true,
        //     loop: true,

        //     navigation: {
        //         nextEl: ".swiper-button-next",
        //         prevEl: ".swiper-button-prev",
        //     },

        //     pagination: {
        //         el: ".swiper-pagination",
        //         clickable: true,
        //     },
        // });

        // eslint-disable-next-line
        let swiper = new Swiper('.portfolio__container', {
            cssMode: true,

            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },

            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
        });
    })

    return (
        <section class="portfolio section" id="portfolio">
            <h2 class="section__title">Portfolio</h2>
            <span class="section__subtitle">Most recent work</span>

            <div class="portfolio__container container swiper-container">
                <div class="swiper-wrapper">

                    <div class="portfolio__content grid swiper-slide">
                        <img src="./img/tokopedia.png" alt="" class="portfolio__img" />

                        <div class="portfolio__data">
                            <h3 class="portfolio__title">Tokopedia Digital Provider</h3>
                            <p class="portfolio__description">Provide comprehensive and reliable digital products for business</p>
                            <a href="https://www.tokopedia.com/partner/digital-aggregator" class="button button--flex button--small portfolio__button">
                                Visit<i class="uil uil-arrow-right button__icon"></i>
                            </a>
                        </div>
                    </div>

                    <div class="portfolio__content grid swiper-slide">
                        <img src="./img/duniagames.png" alt="" class="portfolio__img" />

                        <div class="portfolio__data">
                            <h3 class="portfolio__title">Duniagames</h3>
                            <p class="portfolio__description">Indonesia's most reliable gaming news portal.
                                We have the latest infos of video game, gaming industry, eSports, tech, and pop culture.</p>
                            <a href="https://duniagames.co.id/" class="button button--flex button--small portfolio__button">
                                Visit<i class="uil uil-arrow-right button__icon"></i>
                            </a>
                        </div>
                    </div>

                    <div class="portfolio__content grid swiper-slide">
                        <img src="./img/paragon.png" alt="" class="portfolio__img" />

                        <div class="portfolio__data">
                            <h3 class="portfolio__title">Paragon Bot</h3>
                            <p class="portfolio__description">A chatbot that will route Paragon's customer chat to specific Paragon's Agent</p>
                            <a href="https://www.paragon-innovation.com/" class="button button--flex button--small portfolio__button">
                                Visit<i class="uil uil-arrow-right button__icon"></i>
                            </a>
                        </div>
                    </div>

                    <div class="portfolio__content grid swiper-slide">
                        <img src="./img/myhartono.png" alt="" class="portfolio__img" />

                        <div class="portfolio__data">
                            <h3 class="portfolio__title">MyHartono Bot</h3>
                            <p class="portfolio__description">A chatbot that will route MyHartono's customer chat to specific MyHartono's Agent</p>
                            <a href="https://myhartono.com/en/" class="button button--flex button--small portfolio__button">
                                Visit<i class="uil uil-arrow-right button__icon"></i>
                            </a>
                        </div>
                    </div>

                    <div class="portfolio__content grid swiper-slide">
                        <img src="./img/legoas.png" alt="" class="portfolio__img" />

                        <div class="portfolio__data">
                            <h3 class="portfolio__title">Legoas</h3>
                            <p class="portfolio__description">The first marketplace in Indonesia to sell and buy vehicles through Auctions</p>
                            <a href="https://www.legoas.co.id/" class="button button--flex button--small portfolio__button">
                                Visit<i class="uil uil-arrow-right button__icon"></i>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="swiper-button-next">
                    <i class="uil uil-angle-right-b swiper-portfolio-icon"></i>
                </div>
                <div class="swiper-button-prev">
                    <i class="uil uil-angle-left-b swiper-portfolio-icon"></i>
                </div>

                <div class="swiper-pagination"></div>
            </div>
        </section>
    );
}

export default Portfolio;