function About() {
    return (
        <section class="about section" id="about">
            <h2 class="section__title">About Me</h2>
            <span class="section__subtitle">Introduction</span>

            <div class="about__container container grid">
                <img src="./img/about.png" alt="about" class="about__img" />

                <div class="about__data">
                    <p class="about__description">Software Engineer with several hands-on experiences in writing TRD and documentations,
                        developing, testing, and deploying applications.<br />I’m considering myself as a high curiosity person, hard
                        worker, quick learner, responsible, strong analytical skills with highest level of integrity and able to work independently or team.</p>

                    <div class="about__info">
                        <div>
                            <span class="about__info-title">3+</span>
                            <span class="about__info-name">Years <br /> experience</span>
                        </div>

                        <div>
                            <span class="about__info-title">10+</span>
                            <span class="about__info-name">Completed <br /> project</span>
                        </div>

                        <div>
                            <span class="about__info-title">4</span>
                            <span class="about__info-name">Companies <br /> worked</span>
                        </div>
                    </div>

                    <div class="about__button">
                        <a download="" href="./CV.pdf" class="button button--flex">
                            Download CV<i class="uil uil-download-alt button__icon"></i></a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;