function Footer() {
    return (
        <footer class="footer">
            <div class="footer__bg">
                <div class="footer__container container grid">
                    <div>
                        <h1 class="footer__title">Zein</h1>
                        <span class="footer__subtitle">Software Engineer - BackEnd</span>
                    </div>

                    <ul class="footer__links">
                        <li>
                            <a href="#services" class="footer__link">Services</a>
                        </li>
                        <li>
                            <a href="#portfolio" class="footer__link">Portfolio</a>
                        </li>
                        <li>
                            <a href="#contact" class="footer__link">ContactMe</a>
                        </li>
                    </ul>

                    <div class="footer__socials">
                        <a href="https://www.linkedin.com/in/magaz/" target="_blank" rel="noreferrer" class="footer__social">
                            <i class="uil uil-linkedin-alt"></i>
                        </a>

                        <a href="https://www.facebook.com/magazzein" target="_blank" rel="noreferrer" class="footer__social">
                            <i class="uil uil-facebook-f"></i>
                        </a>

                        <a href="https://www.instagram.com/magaz_" target="_blank" rel="noreferrer" class="footer__social">
                            <i class="uil uil-instagram"></i>
                        </a>
                    </div>
                </div>
                <p class="footer__copy">&#169; Zein. All right reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;